.node {
	fill: #4ac4e2;
	stroke: none;
}

.link {
	fill: none;
	stroke-width: 1px;
	stroke: #ccc;
}

.tooltip {
	background: rgba(196, 196, 196, 0.8);
	border-radius: 2px;
	color: rgb(0, 0, 0);
	font-weight: bold;
	line-height: 1;
	min-width: 155px;
	overflow-wrap: anywhere;
	padding: 12px;
	position: fixed;
}

.dataTreeIcons {
	bottom: 1%;
	position: fixed;
	right: 1%;
}
