.splash-Container {
	align-items: center;
	color: white;
	display: flex;
	flex-direction: column;
	font-size: calc(10px + 2vmin);
	justify-content: center;
	min-height: 35vh;
}

.splash-Logo {
	width: 1em;
}

.splash-Description {
	font-weight: inherit;
}
