html {
	background-color: #000000;
	color: white;
	accent-color: #0000e3;
}

.App {
	height: 100%;
	text-align: center;
	width: 100%;
}

.search-bar {
	border-radius: 20rem;
	border: 1px solid #ced4da;
	box-shadow:
		0 2px 2px 0 rgba(0, 0, 0, 0.16),
		0 0 0 1px rgba(0, 0, 0, 0.08);
	color: black !important;
	display: block;
	font-weight: 400;
	min-width: 200px;
	padding: 0.375rem 0.75rem;
	transition: box-shadow 0.15s ease-in-out;
	width: 50%;

	&:focus {
		outline-offset: 0;
		outline: none;
	}
}

.versionNumber {
	bottom: 1%;
	color: white;
	left: 1%;
	opacity: 0.5;
	position: fixed;
	z-index: 1000;
}

/* Modify Material-UI */
.MuiButton-outlinedSecondary {
	color: #ffadca !important;

	&:hover {
		background-color: #d5eafc !important;
		color: #000000 !important;
	}
}
