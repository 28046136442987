.features-cards {
	background-color: #252d41 !important;
	color: #eeeeef !important;
	margin: 2%;
	transition: all 0.2s ease;

	&:hover {
		.features-icon {
			color: #0aff0a !important;
			transition: all 0.5s ease;
		}
	}
}

.features-icon {
	font-size: 2rem !important;
	transition: all 0.2s ease;
}

.features-title {
	font-size: 2em;
}
