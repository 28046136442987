.toolBarDOI {
	color: white;
	font-size: large;
	text-decoration: none;
}

.info-bar-tabs {
	.MuiTab-root {
		color: #d9d9d9;
	}

	.MuiTab-root.Mui-selected {
		color: #ffffff;
		font-weight: bold;
	}
}

/* Material-UI */
body .MuiAppBar-colorPrimary {
	background-color: #15385b;
}

.MuiTab-wrapper {
	color: white !important;
}

.MuiGrid-container {
	align-items: center;
}
