/* Modify React Material-UI styling */
.MuiInputBase-root {
	background-color: white;
	border-radius: 0.5rem !important;

	&:user-valid {
		border: 1px solid #28a745;
	}

	&:user-invalid {
		border: 1px solid #dc3545;
	}
}

.MuiFormControl-marginNormal {
	margin: 0px !important;
}
