.footer {
	margin: auto;
	padding-bottom: 1%;
}

.footer-Logo {
	color: white !important;
	padding: 0.25em !important;
	transition: all 1s ease !important;
	width: 2.25em !important;
}

.footer-Socials {
	color: white !important;

	.MuiSvgIcon-root {
		border-radius: 50%;
		border: 1px solid #fff !important;
		font-size: 2.25em !important;
		padding: 0.25em !important;
		transition: all 1s ease !important;

		&:hover {
			background-color: #0041b9 !important;
			border: 1px solid #0041b9 !important;
			transition: all 0.25s ease !important;
		}
	}
}
