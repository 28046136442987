.example-Card {
	background-color: #2d364e !important;
	color: white;
	font-size: 1.2em;
	line-height: 1.5em;
	margin: 5%;
	max-width: 90%;
}

.example-HiddenLink {
	color: inherit;
	text-decoration: none;
}

.example-Link {
	color: #54f1c1;
	text-decoration: none;
}

.example-Text {
	color: white !important;
}

.node {
	fill: #4ac4e2;
	stroke: none;
}

.link {
	fill: none;
	stroke-width: 1px;
	stroke: #ccc;
}

.tooltip {
	background: rgba(196, 196, 196, 0.8);
	border-radius: 2px;
	color: rgb(0, 0, 0);
	font-weight: bold;
	line-height: 1;
	max-width: 15%;
	overflow-wrap: anywhere;
	padding: 12px;
	position: fixed;
}
